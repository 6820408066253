<template>
  <div class="dialog-page-container layout-border">
    <PageButton
      :bottomCornerButtonList="bottomCornerButtonList"
      :isLastStep="isLastStep"
      @clickNext="nextPage"
    ></PageButton>
    <div class="dialog-page-wrapper">
      <SceneSwitchThumbnails
        :buttons="buttonList"
        :numBers="numBers"
        @changeTheNumbers="changeTheNumbers"
      >
      </SceneSwitchThumbnails>
      <div class="title-area" v-if="titleInfo" :class="'lesson-' + lessonNo">
        <div class="title">
          <div class="title-pinyin pinyin font-pinyin-medium">
            {{ titleInfo.pinyin }}
          </div>
          <div class="title-hanzi font-hanzi-medium">
            {{ titleInfo.hanzi }}
          </div>
        </div>
      </div>
      <div class="bg-img-area" v-if="!isAllScreen">
        <img :src="bgImg" alt="" />
      </div>
      <div class="dialog-content" :class="'lesson-' + lessonNo">
        <div
          class="page-item"
          v-for="(item, index) in pageInfoList"
          :key="index"
          v-show="currentIndex === item.index"
        >
          <div class="bubble-item" :class="{ allScreen: isAllScreen }">
            <div class="bg-img" ref="levelBg">
              <img :src="item.bgImg" alt="" class="bubble-bg" />
            </div>
            <div
              class="bubble-area"
              :style="{ top: imgTopPos + `px` }"
              ref="bubbleArea"
              v-for="(ele, indexSon) in item.singlePageList"
              :key="indexSon + 'son'"
              :class="'bubble-' + ele.id"
            >
              <transition name="el-fade-in">
                <img
                  :src="ele.bubbleQuestion"
                  v-if="!ele.isShowAnser"
                  class="question moveUpHover"
                  :class="{
                    scaleToAppear: fadeInElements,
                    isHidden: hideElement,
                  }"
                  @click="handleClickBubble(ele)"
                />
              </transition>
              <transition name="el-fade-in">
                <img
                  :src="ele.bubbleAnswer"
                  class="answer"
                  v-if="ele.isShowAnser"
                />
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SceneSwitchThumbnails from "@/components/Course/CoursePage/StorySwitcher/sceneSwitchThumbnails.vue";
import PageButton from "@/components/Course/Interaction/Button/pageButton.vue";
import { startConfetti, playCorrectSound } from "@/utils/tools";

export default {
  name: "DialogPage",
  props: {
    bgImg: {
      type: String,
      require: true,
    },
    pageInfoList: {
      type: Array,
      require: true,
      default: () => [],
    },
    buttonList: {
      type: Array,
      require: true,
      default: () => [],
    },
    clickIdList: {
      type: Array,
      require: true,
      default: () => [],
    },
    lessonNo: {
      type: Number,
      require: true,
    },
    titleInfo: {
      type: Object,
      require: false,
    },
    isAllScreen: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  components: {
    SceneSwitchThumbnails,
    PageButton,
  },
  data() {
    return {
      numBers: 0,
      currentIndex: 1,
      currentIdlist: [],
      isLastStep: false,
      success_audio_sound: require("@/assets/audio/correct_answer.mp3"),

      clickBubbleSocketInfo: {},
      clickMenuSocketInfo: {},
      bottomCornerButtonList: {
        settings: {
          showNextOnLastStep: true,
        },
        buttons: [
          {
            type: "speak",
            startNumberRange: 0,
            endNumberRange: 100,
          },
        ],
      },
      imgTopPos: 0,
      fadeInElements: false,
      hideElement: true,
    };
  },
  watch: {
    // 发送
    clickBubbleSocketInfo: {
      handler(value) {
        // 测试socket
        const data = {
          clickType: 30001,
          data: { value },
          text: "introductoion页面按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
    clickMenuSocketInfo: {
      handler(value) {
        // 测试socket
        const data = {
          clickType: 3000101,
          data: { value },
          text: "introductoion页面侧边菜单栏按钮socket",
        };
        this.sendSocketInfo = { ...data };
        this.$bus.$emit("kids_webSocket_sendInfo", this.sendSocketInfo);
      },
      deep: true,
    },
  },

  mounted() {
    // 接收
    this.$bus.$on("clickIntroSteps", ({ item }) => {
      this.handleClickBubble(item, true);
    });
    this.$bus.$on("clickIntroAside", ({ index }) => {
      this.changeTheNumbers(index, true);
    });
    window.addEventListener("resize", this.updateElementPosition);
    let _this = this;
    setTimeout(function () {
      _this.updateElementPosition();
      _this.fadeInElements = true;
      _this.hideElement = false;
      setTimeout(function () {
        _this.fadeInElements = false;
      }, 300);
    }, 300);
  },
  destroyed() {
    window.removeEventListener("resize", this.updateElementPosition);
  },
  beforeDestroy() {
    this.$bus.$off("clickIntroSteps");
    this.$bus.$off("clickIntroAside");
  },
  methods: {
    updateElementPosition() {
      if (
        undefined !==
        document.querySelectorAll(".bg-img")[this.currentIndex - 1]
      ) {
        this.imgTopPos =
          document.querySelectorAll(".bg-img")[this.currentIndex - 1].offsetTop;
      }
    },
    changeTheNumbers(index, isFromSocket = false) {
      this.hideElement = true;
      this.fadeInElements = false;
      if (!isFromSocket) {
        this.clickMenuSocketInfo = {
          index,
          value: Math.random(),
        };
      }
      // console.log(index);
      this.numBers = index;
      this.currentIndex = index;
      let _this = this;
      setTimeout(function () {
        _this.updateElementPosition();
        setTimeout(function () {
          _this.fadeInElements = true;
          _this.hideElement = false;
          setTimeout(function () {
            _this.fadeInElements = false;
          }, 300);
        }, 200);
      }, 100);
    },
    handleClickBubble(item, isFromSocket = false) {
      if (!isFromSocket) {
        this.clickBubbleSocketInfo = {
          item,
          value: Math.random(),
        };
      }
      // console.log(item.id, this.clickIdList.indexOf(item.id) !== -1);
      if (this.clickIdList.indexOf(item.id) !== -1) {
        // if (item.isShowAnser) {
        //   return;
        // }
        this.currentIdlist.push(item.id);
        this.currentIdlist = Array.from(new Set(this.currentIdlist));
        this.$emit("clickTheBubble", this.currentIndex, item.id);
        if (this.currentIdlist.length === this.clickIdList.length) {
          playCorrectSound();
          startConfetti();
          this.isLastStep = true;
        } else {
          playCorrectSound(true, false);
        }
      }
    },
    nextPage() {
      this.$bus.$emit("nextButton", true);
    },
  },
};
</script>
<style scoped lang="scss">
.dialog-page-container {
  .dialog-page-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
    .title-area {
      z-index: 9;
      position: absolute;
      top: 0;
      left: 0;
      top: 10%;
      width: auto;
      height: auto;
      padding: 20px;
      background: #224e96;
      display: flex;
      align-items: center;
      justify-content: center;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      color: #fff;
      .title {
        .title-pinyin,
        .title-hanzi {
          text-align: center;
        }
      }

      &.lesson-592 {
        background-color: #cd4c3f;
      }
    }
    .lesson-401 {
      width: 30%;
    }
    .bg-img-area {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 58px;
      }
    }
    .dialog-content {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      .page-item {
        width: 100%;
        height: 100%;
        .bubble-item {
          display: flex;
          align-items: center;
          width: 60%;
          height: 90%;
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0;
          bottom: 0;
          margin: auto;
          .bg-img {
            height: fit-content;
            width: 100%;
            // height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .bubble-bg {
              height: 100%;
              width: 100%;
              object-fit: contain;
            }
          }
          .bubble-area {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            img {
              position: absolute;
              z-index: 6;
              &.question {
                cursor: pointer;
              }
            }
          }
        }
        .allScreen {
          width: 100%;
          height: 100%;
          .bg-img {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            .bubble-bg {
              height: 100%;
              width: 100%;
              object-fit: cover;
              border-radius: 48px;
            }
          }
        }
      }
    }
    .lesson-19 {
      .bubble-1 {
        .question {
          top: 7%;
          right: 62%;
          width: 20%;
        }
        .answer {
          top: 7%;
          right: 62%;
          width: 30%;
        }
      }
      .bubble-2 {
        .question {
          left: 61%;
          top: -8%;
          width: 37%;
        }
        .answer {
          left: 61%;
          top: -8%;
          width: 50%;
        }
      }
      .bubble-3 {
        .question {
          left: 0%;
          top: -5%;
          width: 35%;
        }
        .answer {
          left: 0%;
          top: -5%;
          width: 35%;
        }
      }
      .bubble-4 {
        .question {
          left: 70%;
          top: -6%;
          width: 20%;
        }
        .answer {
          left: 65%;
          top: -6%;
          width: 35%;
        }
      }
      .bubble-5 {
        .question {
          left: 26%;
          top: 3%;
          width: 20%;
        }
        .answer {
          left: 13%;
          top: 5%;
          width: 35%;
        }
      }
      .bubble-6 {
        .question {
          left: 80%;
          top: -11%;
          width: 20%;
        }
        .answer {
          left: 76%;
          top: -11%;
          width: 35%;
        }
      }
    }
    .lesson-22 {
      .bubble-1 {
        .question {
          top: 7%;
          right: 62%;
          width: 45%;
        }
        .answer {
          top: 7%;
          right: 62%;
          width: 45%;
        }
      }
      .bubble-2 {
        .question {
          left: 81%;
          top: 17%;
          width: 20%;
        }
        .answer {
          // left: 65%;
          // top: 0%;
          // width: 50%;
          left: 75%;
          top: 18%;
          width: 41%;
        }
      }
      .bubble-3 {
        .question {
          left: -8%;
          top: -5%;
          width: 45%;
        }
        .answer {
          left: -8%;
          top: -5%;
          width: 45%;
        }
      }
      .bubble-4 {
        .question {
          left: 70%;
          top: 0%;
          width: 20%;
        }
        .answer {
          left: 65%;
          top: 0%;
          width: 46%;
        }
      }
      .bubble-5 {
        .question {
          left: -16%;
          top: 3%;
          width: 45%;
        }
        .answer {
          left: -13%;
          top: 5%;
          width: 40%;
        }
      }
      .bubble-6 {
        .question {
          left: 72%;
          top: 0%;
          width: 20%;
        }
        .answer {
          left: 66%;
          top: 0%;
          width: 35%;
        }
      }
      .bubble-7 {
        .question {
          left: -5%;
          top: 5%;
          width: 42%;
        }
        .answer {
          left: -5%;
          top: 5%;
          width: 45%;
        }
      }
      .bubble-8 {
        .question {
          left: 68%;
          top: 5%;
          width: 20%;
        }
        .answer {
          left: 66%;
          top: 5%;
          width: 45%;
        }
      }
    }
    .lesson-21 {
      .bubble-1 {
        .question {
          top: -10%;
          right: 62%;
          width: 45%;
        }
        .answer {
          top: -10%;
          right: 62%;
          width: 45%;
        }
      }
      .bubble-2 {
        .question {
          left: 75%;
          top: -6%;
          width: 20%;
        }
        .answer {
          left: 72%;
          top: -6%;
          width: 40%;
        }
      }
      .bubble-3 {
        .question {
          left: -8%;
          top: 0%;
          width: 45%;
        }
        .answer {
          left: -8%;
          top: 0%;
          width: 45%;
        }
      }
      .bubble-4 {
        .question {
          left: 70%;
          top: 0%;
          width: 20%;
        }
        .answer {
          left: 65%;
          top: 0%;
          width: 46%;
        }
      }
      .bubble-5 {
        .question {
          left: 3%;
          top: -6%;
          width: 45%;
        }
        .answer {
          left: -3%;
          top: -6%;
          width: 40%;
        }
      }
      .bubble-6 {
        .question {
          left: 65%;
          top: -5%;
          width: 22%;
        }
        .answer {
          left: 66%;
          top: -5%;
          width: 42%;
        }
      }
      .bubble-7 {
        .question {
          left: -5%;
          top: -5%;
          width: 42%;
        }
        .answer {
          left: -5%;
          top: -5%;
          width: 35%;
        }
      }
      .bubble-8 {
        .question {
          left: 68%;
          top: -3%;
          width: 20%;
        }
        .answer {
          left: 66%;
          top: -3%;
          width: 35%;
        }
      }
    }
    .lesson-33 {
      .bubble-1 {
        .question {
          top: 7%;
          right: 62%;
          width: 25%;
        }
        .answer {
          top: 7%;
          right: 62%;
          width: 40%;
        }
      }
      .bubble-2 {
        .question {
          top: 6%;
          right: 15%;
          width: 25%;
        }
        .answer {
          top: 1%;
          right: -5%;
          width: 40%;
        }
      }
      .bubble-3 {
        .question {
          top: 10%;
          right: 62%;
          width: 25%;
        }
        .answer {
          top: 7%;
          right: 62%;
          width: 40%;
        }
      }
      .bubble-4 {
        .question {
          top: 6%;
          right: 15%;
          width: 25%;
        }
        .answer {
          top: 0%;
          right: 4%;
          width: 40%;
        }
      }
      .bubble-5 {
        .question {
          top: 3%;
          right: 62%;
          width: 25%;
        }
        .answer {
          top: -7%;
          right: 62%;
          width: 40%;
        }
      }
      .bubble-6 {
        .question {
          top: -1%;
          right: 15%;
          width: 25%;
        }
        .answer {
          top: -10%;
          right: 3%;
          width: 40%;
        }
      }
    }
    .lesson-401 {
      .bubble-1 {
        .question {
          top: 30%;
          right: 63%;
          width: 13%;
        }
        .answer {
          top: 21%;
          right: 63%;
          width: 26%;
        }
      }
      .bubble-2 {
        .question {
          left: 65%;
          top: 0%;
          width: 40%;
        }
        .answer {
          left: 42%;
          top: 13%;
          width: 16%;
        }
      }
      .bubble-3 {
        .question {
          left: -2%;
          top: 0%;
          width: 40%;
        }
        .answer {
          left: -2%;
          top: 0%;
          width: 45%;
          left: 57%;
          top: 47%;
          width: 13%;
        }
        .answer {
          left: 57%;
          top: 47%;
          width: 16%;
        }
      }
      .bubble-4 {
        .question {
          left: 70%;
          top: 0%;
          width: 40%;
        }
        .answer {
          left: 65%;
          top: 0%;
          width: 46%;
          left: 78%;
          top: 50%;
          width: 13%;
        }
        .answer {
          left: 78%;
          top: 50%;
          width: 16%;
        }
      }
      .bubble-5 {
        .question {
          left: -6%;
          top: 3%;
          width: 40%;
        }
        .answer {
          left: -6%;
          top: 5%;
          width: 40%;
          top: 32%;
          right: 63%;
          width: 13%;
        }
        .answer {
          top: 32%;
          right: 63%;
          width: 16%;
        }
      }
      .bubble-6 {
        .question {
          left: 68%;
          top: 0%;
          width: 40%;
        }
        .answer {
          left: 68%;
          top: 0%;
          width: 45%;
        }
      }
    }
    .lesson-34 {
      .bubble-1 {
        .question {
          top: -9%;
          right: 35%;
          width: 30%;
        }
        .answer {
          top: -13%;
          right: 25%;
          width: 45%;
        }
      }
      .bubble-2 {
        .question {
          top: -9%;
          right: 35%;
          width: 30%;
        }
        .answer {
          top: -13%;
          right: 25%;
          width: 45%;
        }
      }
      .bubble-3 {
        .question {
          top: -9%;
          right: 28%;
          width: 30%;
        }
        .answer {
          top: -17%;
          right: 17%;
          width: 45%;
        }
      }
      .bubble-4 {
        .question {
          top: -9%;
          right: 30%;
          width: 30%;
        }
        .answer {
          top: -13%;
          right: 20%;
          width: 45%;
        }
      }
    }
    .lesson-35 {
      .bubble-1 {
        .question {
          top: -13%;
          right: 0%;
          width: 20%;
        }
        .answer {
          top: -14%;
          right: 0%;
          width: 30%;
        }
      }
      .bubble-2 {
        .question {
          left: 60%;
          top: 0%;
          width: 22%;
        }
        .answer {
          left: 60%;
          top: -5%;
          width: 45%;
        }
      }
      .bubble-3 {
        .question {
          left: 55%;
          top: -10%;
          width: 20%;
        }
        .answer {
          left: 55%;
          top: -10%;
          width: 45%;
        }
      }
      .bubble-4 {
        .question {
          left: 70%;
          top: -8%;
          width: 20%;
        }
        .answer {
          left: 67%;
          top: -8%;
          width: 45%;
        }
      }
      .bubble-5 {
        .question {
          left: 60%;
          top: -5%;
          width: 20%;
        }
        .answer {
          width: 47%;
          left: 60%;
          top: -5%;
          // width: 16%;
        }
      }
      .bubble-7 {
        .question {
          left: 57%;
          top: 18%;
          width: 13%;
        }
        .answer {
          left: 57%;
          top: 18%;
          width: 16%;
        }
      }
      .bubble-8 {
        .question {
          left: 69%;
          top: 47%;
          width: 13%;
        }
        .answer {
          left: 69%;
          top: 47%;
          width: 16%;
        }
      }
      .bubble-9 {
        .question {
          top: 55%;
          right: 70%;
          width: 13%;
        }
        .answer {
          top: 55%;
          right: 70%;
          width: 16%;
        }
      }
      .bubble-10 {
        .question {
          left: 30%;
          top: 27%;
          width: 13%;
        }
        .answer {
          left: 30%;
          top: 27%;
          width: 16%;
        }
      }
      .bubble-11 {
        .question {
          left: 48%;
          top: 21%;
          width: 13%;
        }
        .answer {
          left: 48%;
          top: 21%;
          width: 16%;
        }
      }
      .bubble-12 {
        .question {
          left: 67%;
          top: 7%;
          width: 13%;
        }
        .answer {
          left: 67%;
          top: 7%;
          width: 16%;
        }
      }
    }
    .lesson-401 {
      .bubble-1 {
        .question {
          top: 23%;
          right: 63%;
          width: 13%;
        }
        .answer {
          top: 23%;
          right: 63%;
          width: 16%;
        }
      }
      .bubble-2 {
        .question {
          left: 42%;
          top: 15%;
          width: 13%;
        }
        .answer {
          left: 42%;
          top: 13%;
          width: 16%;
        }
      }
      .bubble-3 {
        .question {
          left: 57%;
          top: 46%;
          width: 13%;
        }
        .answer {
          left: 57%;
          top: 46%;
          width: 16%;
        }
      }
      .bubble-4 {
        .question {
          left: 74%;
          top: 46%;
          width: 13%;
        }
        .answer {
          left: 74%;
          top: 46%;
          width: 16%;
        }
      }
      .bubble-5 {
        .question {
          top: 32%;
          left: 17%;
          width: 13%;
        }
        .answer {
          top: 32%;
          left: 17%;
          width: 16%;
        }
      }
      .bubble-6 {
        .question {
          left: 39%;
          top: 52%;
          width: 13%;
        }
        .answer {
          left: 39%;
          top: 52%;
          width: 16%;
        }
      }
      .bubble-7 {
        .question {
          left: 57%;
          top: 18%;
          width: 13%;
        }
        .answer {
          left: 57%;
          top: 18%;
          width: 16%;
        }
      }
      .bubble-8 {
        .question {
          left: 69%;
          top: 47%;
          width: 13%;
        }
        .answer {
          left: 69%;
          top: 47%;
          width: 16%;
        }
      }
      .bubble-9 {
        .question {
          top: 55%;
          right: 70%;
          width: 13%;
        }
        .answer {
          top: 55%;
          right: 70%;
          width: 16%;
        }
      }
      .bubble-10 {
        .question {
          left: 30%;
          top: 27%;
          width: 13%;
        }
        .answer {
          left: 30%;
          top: 27%;
          width: 16%;
        }
      }
      .bubble-11 {
        .question {
          left: 48%;
          top: 21%;
          width: 13%;
        }
        .answer {
          left: 48%;
          top: 21%;
          width: 16%;
        }
      }
      .bubble-12 {
        .question {
          left: 67%;
          top: 7%;
          width: 13%;
        }
        .answer {
          left: 67%;
          top: 7%;
          width: 16%;
        }
      }
    }
    .lesson-40 {
      .bubble-1 {
        .question {
          top: -18%;
          right: 6%;
          width: 57%;
        }
        .answer {
          top: -18%;
          right: 6%;
          width: 57%;
        }
      }
      .bubble-2 {
        .question {
          top: -18%;
          right: 6%;
          width: 57%;
        }
        .answer {
          top: -18%;
          right: 6%;
          width: 57%;
        }
      }
      .bubble-3 {
        .question {
          top: -18%;
          right: 6%;
          width: 57%;
        }
        .answer {
          top: -18%;
          right: 6%;
          width: 57%;
        }
      }
    }

    .lesson-43 {
      .bubble-1 {
        .question {
          top: -15%;
          right: 15%;
          width: 14%;
        }
        .answer {
          top: -18%;
          right: 27%;
          width: 31%;
        }
      }
      .bubble-2 {
        .question {
          top: 6%;
          right: 25%;
          width: 14%;
        }
        .answer {
          top: 0%;
          right: 57%;
          width: 34%;
        }
      }
      .bubble-3 {
        .question {
          top: 16%;
          right: 40%;
          width: 12%;
          @media screen and (max-width: 1300px) {
            top: 11%;
          }
        }
        .answer {
          top: 14%;
          right: 57%;
          width: 22%;
          @media screen and (max-width: 1300px) {
            top: 11%;
          }
        }
      }
      .bubble-4 {
        .question {
          top: 11%;
          right: 41%;
          width: 12%;
          @media screen and (max-width: 1300px) {
            top: 6%;
          }
        }
        .answer {
          top: 10%;
          right: 53%;
          width: 35%;
          @media screen and (max-width: 1300px) {
            top: 8%;
          }
        }
      }
      .bubble-5 {
        .question {
          top: 17%;
          right: 47%;
          width: 12%;
          @media screen and (max-width: 1300px) {
            top: 7%;
          }
        }
        .answer {
          top: 20%;
          right: 58%;
          width: 25%;
          @media screen and (max-width: 1300px) {
            top: 8%;
          }
        }
      }
    }

    .lesson-45 {
      .bubble-1 {
        .question {
          top: 18%;
          right: 59%;
          width: 14%;
          @media screen and (max-width: 1300px) {
            top: 10%;
          }
        }
        .answer {
          top: 18%;
          right: 59%;
          width: 34%;
          @media screen and (max-width: 1300px) {
            top: 11%;
          }
        }
      }
      .bubble-2 {
        .question {
          top: 13%;
          right: 55%;
          width: 14%;
          @media screen and (max-width: 1300px) {
            top: 3%;
          }
        }
        .answer {
          top: 13%;
          right: 55%;
          width: 35%;
          @media screen and (max-width: 1300px) {
            top: 6%;
          }
        }
      }
      .bubble-3 {
        .question {
          top: 26%;
          right: 52%;
          width: 12%;
          @media screen and (max-width: 1300px) {
            top: 17%;
          }
        }
        .answer {
          top: 23%;
          right: 51%;
          width: 22%;
          @media screen and (max-width: 1300px) {
            top: 10%;
          }
        }
      }
    }
    .lesson-49 {
      .bubble-1 {
        .question {
          top: 40%;
          left: 4%;
          width: 20%;
          @media screen and (max-width: 1300px) {
            top: 28%;
          }
        }
        .answer {
          top: 40%;
          left: 4%;
          width: 20%;
          @media screen and (max-width: 1300px) {
            top: 28%;
          }
        }
      }
      .bubble-2 {
        .question {
          top: 50%;
          left: 26%;
          width: 10%;
          @media screen and (max-width: 1300px) {
            top: 28%;
          }
        }
        .answer {
          top: 50%;
          left: 26%;
          width: 20%;
          @media screen and (max-width: 1300px) {
            top: 27%;
          }
        }
      }
      .bubble-3 {
        .question {
          top: 50%;
          left: 4%;
          width: 20%;
          @media screen and (max-width: 1300px) {
            top: 28%;
          }
        }
        .answer {
          top: 50%;
          left: 4%;
          width: 20%;
          @media screen and (max-width: 1300px) {
            top: 27%;
          }
        }
      }
      .bubble-4 {
        .question {
          top: 50%;
          left: 26%;
          width: 10%;
          @media screen and (max-width: 1300px) {
            top: 30%;
          }
        }
        .answer {
          top: 50%;
          left: 26%;
          width: 20%;
          @media screen and (max-width: 1300px) {
            top: 37%;
          }
        }
      }
      .bubble-5 {
        .question {
          top: 40%;
          left: 4%;
          width: 20%;
          @media screen and (max-width: 1300px) {
            top: 28%;
          }
        }
        .answer {
          top: 40%;
          left: 4%;
          width: 20%;
          @media screen and (max-width: 1300px) {
            top: 27%;
          }
        }
      }
      .bubble-6 {
        .question {
          top: 50%;
          left: 26%;
          width: 10%;
          @media screen and (max-width: 1300px) {
            top: 30%;
          }
        }
        .answer {
          top: 50%;
          left: 26%;
          width: 20%;
          @media screen and (max-width: 1300px) {
            top: 37%;
          }
        }
      }
      .bubble-7 {
        .question {
          top: 38%;
          left: 6%;
          width: 10%;
          @media screen and (max-width: 1300px) {
            top: 28%;
          }
        }
        .answer {
          top: 48%;
          left: 4%;
          width: 20%;
          @media screen and (max-width: 1300px) {
            top: 27%;
          }
        }
      }
      .bubble-8 {
        .question {
          top: 43%;
          left: 26%;
          width: 10%;
          @media screen and (max-width: 1300px) {
            top: 30%;
          }
        }
        .answer {
          top: 53%;
          left: 26%;
          width: 20%;
          @media screen and (max-width: 1300px) {
            top: 37%;
          }
        }
      }
      .bubble-9 {
        .question {
          top: 38%;
          left: 6%;
          width: 10%;
          @media screen and (max-width: 1300px) {
            top: 28%;
          }
        }
        .answer {
          top: 48%;
          left: 4%;
          width: 20%;
          @media screen and (max-width: 1300px) {
            top: 27%;
          }
        }
      }
      .bubble-10 {
        .question {
          top: 43%;
          left: 26%;
          width: 10%;
          @media screen and (max-width: 1300px) {
            top: 30%;
          }
        }
        .answer {
          top: 53%;
          left: 26%;
          width: 20%;
          @media screen and (max-width: 1300px) {
            top: 37%;
          }
        }
      }
    }
    .lesson-51 {
      .bubble-1,
      .bubble-3,
      .bubble-7 {
        .question {
          top: 20%;
          left: 33%;
          width: 12%;
          @media screen and (max-width: 1300px) {
            top: 13%;
          }
        }
        .answer {
          top: 17%;
          left: 30%;
          width: 18%;
          @media screen and (max-width: 1300px) {
            top: 13%;
          }
        }
      }
      


      .bubble-2,
      .bubble-4,
      .bubble-6,
      .bubble-8 {
        .question {
          top: 20%;
          left: 63%;
          width: 12%;
          @media screen and (max-width: 1300px) {
            top: 13%;
          }
        }
        .answer {
          top: 20%;
          left: 63%;
          width: 20%;
          @media screen and (max-width: 1300px) {
            top: 13%;
          }
        }
      }

      .bubble-2 {
        .answer {
          width: 18%;
        }
      }
      .bubble-5 {
        .question {
          top: 20%;
          left: 33%;
          width: 12%;
        }
        .answer {
          top: 17%;
          left: 28%;
          width: 22%;
        }
      }
      .bubble-9 {
        .question {
          top: 22%;
          left: 33%;
          width: 12%;
        }
        .answer {
          top: 17%;
          left: 28%;
          width: 22%;
        }
      }
      .bubble-10 {
        .question {
          top: 20%;
          left: 63%;
          width: 12%;
        }
        .answer {
          top: 20%;
          left: 61%;
          width: 26%;
        }
      }
    }
    .lesson-53 {
      .bubble-1 {
        .question {
          top: 26%;
          right: 55%;
          width: 12%;
          @media screen and (max-width: 1300px) {
            top: 17%;
          }
        }
        .answer {
          top: 27%;
          right: 45%;
          width: 26%;
          @media screen and (max-width: 1300px) {
            top: 19%;
          }
        }
      }
      .bubble-2 {
        .question {
          top: 13%;
          right: 50%;
          width: 12%;
          @media screen and (max-width: 1300px) {
            top: 9%;
          }
        }
        .answer {
          top: 13%;
          right: 40%;
          width: 26%;
          @media screen and (max-width: 1300px) {
            top: 10%;
          }
        }
      }
      .bubble-3 {
        .question {
          top: 26%;
          right: 60%;
          width: 11%;
          @media screen and (max-width: 1300px) {
            top: 16%;
          }
        }
        .answer {
          top: 23%;
          right: 51%;
          width: 26%;
          @media screen and (max-width: 1300px) {
            top: 16%;
          }
        }
      }
      .bubble-4 {
        .question {
          top: 10%;
          right: 50%;
          width: 12%;
          @media screen and (max-width: 1300px) {
            top: 8%;
          }
        }
        .answer {
          top: 10%;
          right: 40%;
          width: 26%;
          @media screen and (max-width: 1300px) {
            top: 7%;
          }
        }
      }
    }
    .lesson-57 {
      .bubble-1 {
        .question {
          bottom: 7%;
          left: 17%;
          width: 35%;
        }
        .answer {
          bottom: 7%;
          left: 17%;
          width: 35%;
        }
      }
      .bubble-2,
      .bubble-3,
      .bubble-4,
      .bubble-5 {
        .question {
          bottom: 10%;
          left: 17%;
          width: 40%;
        }
        .answer {
          bottom: 10%;
          left: 17%;
          width: 40%;
        }
      }
    }
    .lesson-591 {
      .bubble-1 {
        .question {
          top: 30%;
          right: 60%;
          width: 15%;
        }
        .answer {
          top: 30%;
          right: 50%;
          width: 32%;
        }
      }
      .bubble-2 {
        .question {
          top: 30%;
          right: 38%;
          width: 15%;
        }
        .answer {
          top: 30%;
          right: 25%;
          width: 32%;
        }
      }
      .bubble-3 {
        .question {
          top: 30%;
          right: 55%;
          width: 15%;
        }
        .answer {
          top: 35%;
          right: 40%;
          width: 32%;
        }
      }
      .bubble-4 {
        .question {
          top: 30%;
          right: 55%;
          width: 15%;
        }
        .answer {
          top: 24%;
          right: 40%;
          width: 32%;
        }
      }
    }
    .lesson-592 {
      .bubble-1 {
        .question {
          top: 26%;
          right: 50%;
          width: 15%;
        }
        .answer {
          top: 26%;
          right: 40%;
          width: 32%;
        }
      }
      .bubble-2 {
        .question {
          top: 40%;
          right: 35%;
          width: 15%;
        }
        .answer {
          top: 40%;
          right: 25%;
          width: 32%;
        }
      }
      .bubble-3 {
        .question {
          top: 45%;
          right: 40%;
          width: 15%;
        }
        .answer {
          top: 48%;
          right: 28%;
          width: 32%;
        }
      }
      .bubble-4 {
        .question {
          top: 45%;
          right: 28%;
          width: 15%;
        }
        .answer {
          top: 45%;
          right: 16%;
          width: 32%;
        }
      }
    }
    .lesson-61 {
      .bubble-1,
      .bubble-2,
      .bubble-3,
      .bubble-4 {
        .question {
          top: 38%;
          left: 8%;
          width: 15%;
        }
        .answer {
          top: 38%;
          left: 5%;
          width: 32%;
        }
      }
    }
  }
}
</style>
